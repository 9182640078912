html {
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 350px) {
    font-size: 12px;
  }
  scroll-behavior: auto;
}
.App {
  background-color: #e8f3f3;
  min-height: 100vh;
}
* {
  padding: 0;
  margin: 0;
  color: #405c6e;
}
.navbar {
  position: fixed;
  background-color: transparent;
  top: 0px;
  width: 100%;
}
.navbar-container {
  position: relative;
  height: 0px;
}

.mh-100vh {
  min-height: 100vh;
}
.mh-100vhm {
  min-height: 100vh;
  @media screen and (max-width: 900px) {
    min-height: 50vh;
  }
}
.flex-1 {
  flex: 1;
}
.cursor-pointer {
  cursor: pointer;
}
.subscription-bg {
  @media screen and (min-width: 600px) {
    background-image: url("../assets/subscription-bg.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: start;
  }
}
.subscription-bg-2 {
  border-style: outset;
  background-image: url('https://foamlaundry.com.au/wp-content/uploads/2022/10/foam-tile-bg.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 48% auto;
  opacity: 1;
}
.order-bottom-bar {
  background-image: url("../assets/order-bottom-bar.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: start;
}
.arrow-left {
  position: relative;
  z-index: 2;
}
.arrow-left::before {
  content: "";
  width: 20px;
  height: 20px;

  border-radius: 3px;
  position: absolute;
  transform: rotate(45deg) translate(-50%, 10%);
  z-index: 1;
  background-color: white;
  left: 50%;
  top: 0;
  @media screen and (min-width: 600px) {
    left: 0;
    top: 50%;
  }
}
.cart-img {
  width: 100%;
  max-width: 130px;
  height: auto;
}

// Overide MUI Styles
.MuiFilledInput-input {
  background-color: #fff !important;
}
.css-1qp38ge-MuiLinearProgress-root-MuiMobileStepper-progress {
  width: 100% !important;
}
.MuiInputBase-root {
  background-color: white !important;
}
.react-horizontal-scrolling-menu--scroll-container {
  margin: 0px 3px;
  @media screen and (min-width: 600px) {
    overflow: hidden;
  }
}

$theme-colors: (
  "light": #e8f3f3,
  "dark": #405c6e,
  "white": #fff,
  "secondary": #ffbbae,
  "success": #39b555,
  "info": #bbdad9,
);
@import url(//db.onlinewebfonts.com/c/72bc3c6b00e72d272eb79ee0d69ce103?family=New+Spirit);
@import "~bootstrap/scss/bootstrap";

.fm-load-list {
  margin: 16px 0;
  list-style: none;
  padding: 0;

  & li {
    line-height: 32px;
  }

  & li svg {
    margin-right: 8px;
  }
}

.fm-generic-list {
  margin: 16px 0;
  padding: 0 16px;

  & li {
    margin-bottom: 16px;
  }

  & li:last-child {
    margin-bottom: 0;
  }
}

.fm-mui-list {
  li {
    padding-left: 0;
    padding-right: 0;
    align-items: flex-start;
  }
}

.fm-disclaimers {
  p {
    margin-bottom: 8px;
  }
  h6 {
    margin: 16px 0 8px 0;
  }
}

.fm-main-container-mobile {
  @media only screen and (max-width: 599px) {
    padding-top: 24px !important;
    padding-bottom: 40px !important;
  }
}

.fm-vessel-image {
  @media only screen and (max-width: 599px) {
    max-width: 170px;
  }
}

.fm-kilo-wrapper {
  @media only screen and (max-width: 599px) {
    text-align: center;
  }

  h5 {
    @media only screen and (max-width: 599px) {
      align-items: center;
      justify-content: center;
    }
  }
}